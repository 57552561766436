<template>
  <div>
    <headers />
    <div class="bg">

      <div class="line1"></div>
      <div class="if">
        <div>
          <div style="font-size: .35rem;font-weight: bold">代理商查询:</div>
          <div class="con">
            代理商编号：<input v-model="params.agentNo" style="height: .8rem !important;margin-bottom:5px;width:70%;font-size:.3rem;color:#111;border-radius: 0.1rem;" /><br>
            手机后四位：<input v-model="params.last4No" style="height: .8rem !important;width:70%;font-size: .3rem;color:#111;border-radius: 0.1rem;" /><br>
            <el-button  type="primary" style="font-size: .3rem;line-height: 0.5;margin-bottom: 10px;" @click="getAgentInfo">查询</el-button>
          </div>
        </div>
      </div>

      <div v-if="resultShow" style="position: relative;display: flex;justify-content: center;">

        <el-card :class="getLevelClass(agentInfo.level)"  style="width: 100%;font-size: .5rem; position: relative" :body-style="{ padding: '0px' }">
          <img :src="agentInfo.avatarUrl" class="image">

          <div style="margin-left: 30%">
            编号：<span>{{ agentInfo.agentNo}}</span>
          </div>
          <div v-if="agentInfo.name" style="margin-left: 30%">
            名称：<span>{{ agentInfo.name }}</span>
          </div>
          <div style="margin-left: 30%">
            姓名：<span>{{ agentInfo.contacts }}</span>
          </div>
          <!--          <div style="margin-left: 30%">-->
          <!--            等级：<span><i class="el-icon-s-flag" style="color: gold;"/>金牌</span>-->
          <!--          </div>-->
          <div style="text-align: center">
              <span style="font-size: .3rem;">

              </span>
            <div class="bottom clearfix">
              <time class="time">数壤官方认证代理商资质</time>
            </div>
          </div>
        </el-card>


      </div>


      <div class="line1 blue"></div>
      <div class="if">
        <div class="text1">
          <div>如果你:</div>
          <div class="con">· 是各种制造业行业大拿、协会负责人等 <br>· 拥有广泛行业和企业人脉资源<br> · 正在寻找快实施、稳利润的产品<br/>· 正在寻找低投入、高回报项目<br/>· 想要加入数字化转型浪潮</div>
        </div>
        <div class="text2">欢迎你加入  “数字厂长” 代理商阵营，这里你将会收获你想要的！</div>
      </div>

    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/H5/hend';
import footers from '@/components/H5/foot';
import agentApi from "@/assets/api/agent/agent";

export default {
  components: {
    headers,
    footers
  },
  data(){
    return{
      resultShow:false,
      params:{
        agentNo:'',
        last4No:''
      },
      agentInfo:{},
      bageClass : 'jinpai'
    }
  },
  methods:{
    getLevelClass(level){
      switch (String(level)) {
        case '1':
          this.bageClass = 'zuanshi';
          break;
        case '2':
          this.bageClass = 'jinpai';
          break;
        case '3':
          this.bageClass = 'yinpai';
          break;
        case '4':
          this.bageClass = 'tongpai';
          break;
      }
      return this.bageClass;

    },
    getAgentInfo(){
      // this.agentInfo = {
      //     "id": 3,
      //     "agentNo": "000051",
      //     "name": "中星",
      //     "shortName": "zx",
      //     "contacts": "苏生安",
      //     "telephone": "15643234245",
      //     "level": 4,
      //     "avatar": "{\"fileName\":\"blob\",\"size\":69605,\"ossId\":1416,\"url\":\"https://plm-document.obs.cn-east-3.myhuaweicloud.com:443/agent-avatar%2Fprovider_0%2F2024%2F05%2F30%2Fcf5fc608c2e84cb88a025c370b3ebadb%2Fblob\"}",
      //     "province": "310000",
      //     "city": "310000",
      //     "area": "310110",
      //     "remark": "挖掘",
      //     "strongArea": "装备制造",
      //     "createTime": "2024-05-30 13:40:32",
      //     "createBy": "admin",
      //     "updateTime": "2024-05-30 15:23:32",
      //     "updateBy": "admin",
      //     "isDelete": 0
      // }
      if(this.params.agentNo === null || '' === this.params.agentNo){
        this.$message.warning("请输入代理商编号！")
        return;
      }
      if(this.params.last4No === null || '' === this.params.last4No){
        this.$message.warning("请输入代理商手机号后四位！")
        return;
      }
      agentApi.agentSearch(this.params).then((res) => {
        console.log(res)
        if(res.code === 200){
          if(res.data === null && res.msg !== '' ){
            this.$message.warning(res.msg)
          }else{
            this.resultShow = true;
            this.agentInfo = res.data;
          }
        }else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.bg{
  width: 100%;
  // height: px2rem(5920);
  height: 100%;
  background: url(../../../assets/image/partner/bg1.png) center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.h1 {
  margin: 31px auto 0;
  width: 184px;
  height: 19px;
  display: block;
}

.h2 {
  margin: 15px auto 0;
  width: 335px;
  height: 30px;
  display: block;
}

.line1 {
  position: relative;
  margin: 22px auto 0;
  width: 325px;
  height: 1px;
  border-top: 1px solid;
  border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

  &:after {
    position: absolute;
    top: -2.6px;
    right: 99px;
    content: '';
    width: 5px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 50%;
  }

  &.blue {
    &::after {
      background: #9EFCFF;
      left: 99px;
    }
  }
}

.if {
  margin: 12px auto 0;
  width: 85%;

  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: rgba(255, 255, 255, 0.8);
  line-height: 26px;

  .text1 {
    display: flex;

    .con {
      margin-left: 5px;
    }
  }
}
.titleHed {
  &.hen1 {
    margin-top: 35px;
  }

  &.hen2 {
    margin-top: 30px;
  }

  position: relative;
  margin: auto;
  width: 345px;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 50%;
    left: 0;
    top: 6px;
  }

  &::after {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background: #FFFFFF;
    border-radius: 50%;
    right: 0;
    top: 6px;
  }

  &.moreline {
    &::before {
      top: 14px;
    }

    &::after {
      top: 14px;
    }
  }

  .titleline {
    width: 50%;
    height: 1px;
    background: #FFFFFF;
  }

  .titlename {
    margin: 0 26px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16px;
    word-break: keep-all;
  }
}


.time {
  font-size: .5rem;
  color: #999;
}

.bottom {
  margin-top: .1rem;
  line-height: 1.28rem;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.jinpai:before {
  content: "金牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color:#FFD700;
  transform: rotate(45deg);
  font-size:.5rem;
  color: #FFFFFF;
}
.zuanshi:before {
  content: "钻石";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #63dfff;
  transform: rotate(45deg);
  font-size:.5rem;
  color: #FFFFFF;
}

.yinpai:before {
  content: "银牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #C0C0C0;
  transform: rotate(45deg);
  font-size:.5rem;
  color: #FFFFFF;
}

.tongpai:before {
  content: "铜牌";
  position: absolute;
  top: 5px;
  right: -15px;
  padding: 5px 22px;
  background-color: #CD7F32;
  transform: rotate(45deg);
  font-size:.5rem;
  color: #FFFFFF;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
